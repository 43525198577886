export const precisionNetFormController = (form) => {
    if (!form) {
        console.warn('no precision form passed to the precisionNetFormController');
        return;
    }

    const vehicleInfoRequired = form.querySelector('#vehicleInfoRequired');
    const isGpsSystem = form.querySelector('#isGpsSystem');
    const vehicleBrand = form.querySelector('#vehicleBrand');
    const vehicleType = form.querySelector('#vehicleType');

    if (!isGpsSystem || !vehicleBrand || !vehicleType) {
        console.warn('missing one or more of the isGpsSystem, vehicleBrand or vehicleType input fields');
        return;
    }

    if (isGpsSystem.checked) {
        vehicleBrand.disabled = false;
        vehicleType.disabled = false;
    } else {
        vehicleBrand.disabled = true;
        vehicleType.disabled = true;
    }

    if(vehicleBrand.dataset.error || vehicleType.dataset.error) {
        isGpsSystem.checked = true;
        vehicleBrand.disabled = false;
        vehicleType.disabled = false;
    }

    const disableVehicleField = (field) => {
        field.innerText = '';
        field.value = '';
        field.required = false;
        field.placeholder = field.placeholder.replace('* ', '');
        field.disabled = true;
        vehicleInfoRequired.value = '0';
    }

    const enableVehicleField = (field) => {
        field.required = true;
        field.placeholder = `* ${field.placeholder}`;
        field.disabled = false;
        vehicleInfoRequired.value = '1';
    }

    isGpsSystem.onchange = (e) => {
        if(e.target.checked) {
            enableVehicleField(vehicleBrand);
            enableVehicleField(vehicleType);
        } else {
            disableVehicleField(vehicleBrand);
            disableVehicleField(vehicleType);
        }
    };
};
