/**
 * @param timelineBlock
 * @return TimelineController
 */
let TimelineController = (timelineBlock) => {
    if (!timelineBlock) {
        console.warn('no timeline block passed to timelineController');
        return undefined;
    }

    const timelineContentBlocks = Array.from(document.querySelectorAll('.block-timeline-content-wrapper'));

    const findIndex = (contentBlock) => timelineContentBlocks.findIndex((cb) => cb === contentBlock);

    const isFirstContentBlockInTimeline = (contentBlock) => {
        return contentBlock === timelineContentBlocks[0];
    };

    const getPreviousContentBlock = (contentBlock) => {
        const idx = findIndex(contentBlock);
        if (idx === 0) {
            return undefined;
        }
        return timelineContentBlocks[idx - 1];
    };

    const getRelevantContentBlock = () => {
        const foundContentBlocks = timelineContentBlocks.filter(contentBlock => {
            const top = contentBlock.getBoundingClientRect().top;
            const toThirds = (window.innerHeight / 3) * 2;

            return top < toThirds;
        });

        if (foundContentBlocks.length) {
            return foundContentBlocks.pop();
        }

        return undefined;
    };

    const getAllPrevious = (contentBlock) => {
        const idx = findIndex(contentBlock);
        if (idx === 0) {
            return [];
        }

        return timelineContentBlocks.slice(0, idx);
    };

    const getAllAfter = (contentBlock) => {
        const idx = findIndex(contentBlock);
        if (idx === timelineContentBlocks.length) {
            return [];
        }

        return timelineContentBlocks.slice(idx + 1, timelineContentBlocks.length);
    };

    const setLineZIndices = () => {
        [...timelineContentBlocks].reverse().forEach((cb, idx) => {
            const line = cb.querySelector('.line');
            line.style.zIndex = idx + 1;
        })
    }

    const setAllPreviousToFinished = (contentBlock) => {
        getAllPrevious(contentBlock).forEach(cb => {
            cb.classList.remove('started');
            cb.classList.add('finished');
        });
    };

    const setAllAfterToClassless = (contentBlock) => {
        getAllAfter(contentBlock).forEach(cb => {
            cb.classList.remove('started');
            cb.classList.remove('finished');
        });
    };

    const isTimelineInViewport = () => {
        const timelineBlockRect = timelineBlock.getBoundingClientRect();
        return timelineBlockRect.top <= window.innerHeight && timelineBlockRect.top >= (-1 * timelineBlockRect.height);
    };

    /**
     * @typedef {{
     *   timelineBlock: Element,
     *   timelineContentBlocks: [],
     *   getPreviousContentBlock: (function(Element): Element),
     *   getRelevantContentBlock: (function(): Element),
     *   isFirstContentBlockInTimeline: (function(Element): boolean),
     *   setAllPreviousToFinished: (function(Element): undefined),
     *   setAllAfterToClassless: (function(Element): undefined),
     *   isTimelineInViewport: (function(): boolean),
     *   setLineZIndices: (function(): undefined)
     * }} TimelineController
     */
    return {
        timelineBlock,
        timelineContentBlocks: timelineContentBlocks,
        getPreviousContentBlock: getPreviousContentBlock,
        getRelevantContentBlock: getRelevantContentBlock,
        isFirstContentBlockInTimeline: isFirstContentBlockInTimeline,
        setAllPreviousToFinished: setAllPreviousToFinished,
        setAllAfterToClassless: setAllAfterToClassless,
        isTimelineInViewport: isTimelineInViewport,
        setLineZIndices: setLineZIndices,
    };
};

/**
 * @param {TimelineController} controller
 */
const handleScrollUp = (controller) => () => {
    const contentBlock = controller.getRelevantContentBlock();

    if (contentBlock) {
        const previous = controller.getPreviousContentBlock(contentBlock);

        controller.setAllPreviousToFinished(previous);
        controller.setAllAfterToClassless(previous);

        if (!controller.isFirstContentBlockInTimeline(contentBlock)) {
            previous.classList.remove('finished');
            previous.classList.add('started');
        }
    }
};

/**
 * @param {TimelineController} controller
 */
const handleScrollDown = (controller) => () => {
    const contentBlock = controller.getRelevantContentBlock();

    if (contentBlock && !controller.isFirstContentBlockInTimeline(contentBlock)) {
        const previous = controller.getPreviousContentBlock(contentBlock);

        controller.setAllPreviousToFinished(previous);
        controller.setAllAfterToClassless(previous);

        previous.classList.add('started');
    }
};

/**
 * @param {TimelineController} controller
 */
const handleScroll = (controller) => {
    let timer;
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

    window.addEventListener('scroll', function () {
        if (controller.isTimelineInViewport()) {
            const st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > lastScrollTop) {
                clearTimeout(timer);
                timer = setTimeout(handleScrollDown(controller), 10);
            } else {
                clearTimeout(timer);
                timer = setTimeout(handleScrollUp(controller), 10);
            }
            lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        }
    }, false);
};

/**
 * @param {TimelineController} controller
 */
let startTimeline = (controller) => {
    controller.setLineZIndices();
    handleScroll(controller);
};

window.TimelineController = TimelineController;
window.startTimeline = startTimeline;
