/**
 * @param {Element} usedVehiclesBlock
 */
export const usedVehiclesController = (usedVehiclesBlock) => {
    if (usedVehiclesBlock) {
        const iframe = usedVehiclesBlock.querySelector('[id^="used-vehicles-iframe"]');
        const spinner = usedVehiclesBlock.querySelector('[id^="used-vehicles-spinner"]');

        if (iframe && spinner) {
            iframe.onload = () => {
                spinner.classList.remove('d-flex');
                spinner.classList.add('spinner-display-none');
            }
        }
    }
}
