import {
    debounce,
    findParentElementWithType,
    getElementCoords,
    isViewPortMaxWidth,
    Observable,
    viewportSize
} from '../../_assets/scripts/utils';

const transitionDuration = 250;
const showSubnavigationClass = 'show-sub-navigation';
const selectedClass = 'selected';
const isSmallScreenClass = 'is-small-screen';
const isLargeScreen = 'is-large-screen';
const navBarOpenClass = 'navbar-open';

/**
 * @param {Element} navigation
 * @param {Element} overlay
 *
 * @return {Readonly<NavigationController>}
 */
let NavigationController = ({navigation, overlay}) => {
    const body = document.querySelector('body');
    const mainNavigation = navigation.querySelector('.main-navigation');
    const mainNavigationEntries = navigation.querySelectorAll('.main-navigation-entry');
    const navigationWrapper = navigation.querySelector('.navigation-wrapper');
    const desktopSearchWrapper = navigation.querySelector('#desktop-search');
    const subSearchWrapper = navigation.querySelector('#sub-search-wrapper');
    const subSearchInput = subSearchWrapper.querySelector('input');
    const menuBurger = navigation.querySelector('#menuicon');
    const subNavigationToggleButton = navigation.querySelector('#navbar-toggle-button');
    const subNavigationWrapper = navigation.querySelector('.sub-navigation-wrapper');
    const mobileSubNavToggleButtons = navigation.querySelectorAll('.mobile-sub-nav-toggle-button');
    const navigationLinks = navigation.querySelectorAll('a');
    const [desktopSearchButton] = desktopSearchWrapper.children;
    const [desktopSearchSvgIcon, desktopSearchTimesSvgIcon] = desktopSearchButton.children;
    const selectedMenuId = Observable(undefined);
    const isSmallScreen = Observable(undefined);
    const bodyIsLocked = Observable(false);

    const gutter = Number(getComputedStyle(document.documentElement)
        .getPropertyValue('--grid-gutter-width')
        .replace('px', ''));

    const hasSearchInputFocus = () => {
        return document.activeElement === subSearchInput;
    };

    const clearSelectedNavigationEntries = () => {
        mainNavigationEntries.forEach((entry) => entry.classList.remove(selectedClass));
    };

    const hideOverlay = () => {
        overlay.style.display = 'block';
        overlay.style.opacity = '0';
    };

    const showOverlay = () => {
        overlay.style.display = 'block';
    };

    let scrollPosition = undefined;
    const lockBody = () => {
        scrollPosition = getElementCoords(navigation).top - gutter;
        body.style.top = -1 * (scrollPosition) + 'px';
        body.style.left = getElementCoords(navigation).left + 'px';
        body.style.overflowY = 'scroll';
        body.style.position = 'fixed';
        body.style.width = '100%';
        bodyIsLocked.setValue(true);
    };

    const unlockBody = () => {
        body.removeAttribute('style');
        if (scrollPosition) {
            window.scrollTo(0, scrollPosition);
        }
        setTimeout(() => {
            bodyIsLocked.setValue(false);
        }, 1000);
    };

    const closeSearchSubmenu = () => {
        subSearchWrapper.classList.remove(showSubnavigationClass);
        desktopSearchSvgIcon.removeAttribute('style');
        desktopSearchTimesSvgIcon.setAttribute('display', 'none');
    };

    const openSearchSubmenu = () => {
        subSearchWrapper.classList.add(showSubnavigationClass);
        desktopSearchSvgIcon.style.display = 'none';
        desktopSearchTimesSvgIcon.removeAttribute('display');
        clearSelectedNavigationEntries();
    };

    const handleMouseEnter = () => {
        hideOverlay();

        $(overlay).animate({
            opacity: 1,
        }, transitionDuration, function () {
            showOverlay();
            lockBody();
        });
    };

    const handleMouseLeave = () => {
        $(overlay).animate({
            opacity: 0,
        }, transitionDuration, function () {
            closeSearchSubmenu();
            clearSelectedNavigationEntries();
            selectedMenuId.setValue(undefined);
            unlockBody();
            overlay.style.display = 'none';
        });
    };

    const closeSmallScreenSubNavigation = () => {
        menuBurger.classList.remove(navBarOpenClass);
        subNavigationWrapper.style.display = 'none';
    };

    const openSmallScreenSubNavigation = () => {
        menuBurger.classList.add(navBarOpenClass);
        subNavigationWrapper.removeAttribute('style');
    };

    const toggleSmallScreenSubNavigation = () => {
        if (menuBurger.classList.contains(navBarOpenClass)) {
            closeSmallScreenSubNavigation();
            handleMouseLeave();
        } else {
            handleMouseEnter();
            openSmallScreenSubNavigation();
        }
    };

    isSmallScreen.onChange((newVal, oldVal) => {
        if (newVal !== oldVal) {
            if (newVal) {
                navigationWrapper.classList.add(isSmallScreenClass);
                navigationWrapper.classList.remove(isLargeScreen);
                closeSmallScreenSubNavigation();
                handleMouseLeave();
            } else {
                navigationWrapper.classList.add(isLargeScreen);
                navigationWrapper.classList.remove(isSmallScreenClass);
                openSmallScreenSubNavigation();
                handleMouseLeave();
            }
        }
    });

    /**
     * @typedef {{
     *      navigation: Element,
     *      overlay: Element,
     *      body: Element,
     *      mainNavigation: Element,
     *      mainNavigationEntries: NodeList,
     *      mobileSubNavToggleButtons: NodeList,
     *      navigationWrapper: Element,
     *      selectedMenuId: Observable,
     *      isSmallScreen: Observable,
     *      bodyIsLocked: Observable,
     *      desktopSearchButton: Element,
     *      subSearchWrapper: Element,
     *      desktopSearchSvgIcon: Element,
     *      desktopSearchTimesSvgIcon: Element,
     *      subNavigationToggleButton: Element,
     *      clearSelectedNavigationEntries: function(): undefined,
     *      hideOverlay: function(): undefined,
     *      lockBody: function(): undefined,
     *      unlockBody: function(): undefined,
     *      handleMouseEnter: function(): undefined,
     *      handleMouseLeave: function(): undefined,
     *      closeSearchSubmenu: function(): undefined,
     *      openSearchSubmenu: function(): undefined,
     *      toggleSmallScreenSubNavigation: function: undefined,
     *      navigationLinks: NodeList,
     *      gutter: Number,
     *      hasSearchInputFocus: function(): boolean
     * }} NavigationController
     */
    return Object.freeze({
        navigation,
        overlay,
        body,
        mainNavigation,
        mainNavigationEntries,
        mobileSubNavToggleButtons,
        navigationWrapper,
        selectedMenuId,
        isSmallScreen,
        desktopSearchButton,
        subSearchWrapper,
        desktopSearchSvgIcon,
        desktopSearchTimesSvgIcon,
        subNavigationToggleButton,
        clearSelectedNavigationEntries,
        hideOverlay,
        lockBody,
        unlockBody,
        handleMouseEnter,
        handleMouseLeave,
        closeSearchSubmenu,
        openSearchSubmenu,
        toggleSmallScreenSubNavigation,
        navigationLinks,
        gutter,
        hasSearchInputFocus,
        bodyIsLocked
    });
};

/**
 * @param {NavigationController} controller
 */
const registerMouseHandler = (controller) => {
    if (controller.navigation) {
        let timeout;
        controller.navigationWrapper.onmousemove = (elem) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {

                const mainNavigationEntry = elem.target.dataset.hasSubEntries !== undefined ? elem.target : undefined;

                if (mainNavigationEntry) {
                    controller.closeSearchSubmenu();
                    controller.clearSelectedNavigationEntries();
                    mainNavigationEntry.classList.add(selectedClass);

                    const mainNavigationEntryId = mainNavigationEntry.dataset.id;
                    const hasSubEntries = Boolean(Number(mainNavigationEntry.dataset.hasSubEntries));

                    if (hasSubEntries && mainNavigationEntryId) {
                        controller.selectedMenuId.setValue(mainNavigationEntryId);
                    } else {
                        controller.selectedMenuId.setValue(undefined);
                    }
                }
            }, 100);
        };

        controller.navigationWrapper.onmouseenter = () => {
            if (!controller.isSmallScreen.getValue() && !controller.hasSearchInputFocus()) {
                controller.handleMouseEnter();
            }
        };

        controller.navigationWrapper.onmouseleave = () => {
            if (!controller.isSmallScreen.getValue() && !controller.hasSearchInputFocus()) {
                controller.handleMouseLeave();
            }
        };
    }
};

/**
 * @param {NavigationController} controller
 */
const registerViewportHandler = (controller) => {
    const setIsSmallScreen = () => {
        if (isViewPortMaxWidth(viewportSize.lg)) {
            controller.isSmallScreen.setValue(true);
        } else {
            controller.isSmallScreen.setValue(false);
        }
    };

    setIsSmallScreen();
    window.addEventListener('resize', () => debounce(setIsSmallScreen, 100));
};

/**
 * @param {NavigationController} controller
 */
const handleSubNavigation = (controller) => {
    controller.selectedMenuId.onChange((newId, oldId) => {
        if (oldId) {
            const subMenu = document.querySelector(`#${oldId}`);
            subMenu.classList.remove(showSubnavigationClass);
        }

        if (newId) {
            const subMenu = document.querySelector(`#${newId}`);
            subMenu.classList.add(showSubnavigationClass);
        }
    });
};

/**
 * @param {NavigationController} controller
 */
const handleDesktopSearch = (controller) => {
    controller.desktopSearchButton.onclick = () => {
        controller.selectedMenuId.setValue(undefined);

        if (!controller.subSearchWrapper.classList.contains(showSubnavigationClass)) {
            controller.openSearchSubmenu();
        } else {
            controller.closeSearchSubmenu();
        }
    };
};

/**
 * @param {NavigationController} controller
 */
const registerMobileClickHandler = (controller) => {
    controller.subNavigationToggleButton.onclick = () => {
        controller.toggleSmallScreenSubNavigation();
    };
};

/**
 * @param {NavigationController} controller
 */
const registerMobileSubnavigationButtons = (controller) => {
    const toggleMobileSubNavigation = (mobileSubNavButton) => {
        const parentUl = findParentElementWithType(mobileSubNavButton, 'ul');

        if (parentUl.classList.contains(selectedClass)) {
            parentUl.classList.remove(selectedClass);
        } else {
            parentUl.classList.add(selectedClass);
        }
    };

    Array.from(controller.mobileSubNavToggleButtons).forEach(mobileSubNavButton => {
        mobileSubNavButton.onclick = () => {
            toggleMobileSubNavigation(mobileSubNavButton);
        };
    });
};

/**
 * @param {NavigationController} controller
 */
const handleScroll = (controller) => {
    let timer;
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const removeIsScrollingDown = () => {
        controller.navigation.classList.remove('scrolling-down');
    };

    const setIsScrollingDown = () => {
        controller.navigation.classList.add('scrolling-down');
    };

    window.addEventListener('scroll', function () {
        if (!controller.bodyIsLocked.getValue()) {
            const st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > lastScrollTop) {
                clearTimeout(timer);
                timer = setTimeout(setIsScrollingDown, 20);
            } else {
                clearTimeout(timer);
                timer = setTimeout(removeIsScrollingDown, 20);
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
    }, false);
};

const startNavigation = (controller) => {
    registerViewportHandler(controller);
    registerMouseHandler(controller);
    registerMobileClickHandler(controller);
    registerMobileSubnavigationButtons(controller);
    handleSubNavigation(controller);
    handleDesktopSearch(controller);
    handleScroll(controller);
};

window.NavigationController = NavigationController;
window.startNavigation = startNavigation;
