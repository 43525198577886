import {animate} from '../_assets/scripts/utils.js';
import {tween} from '../_assets/scripts/utils';

/**
 *
 * @param {Element} cardNumber
 * @return {undefined}
 */
export const titleNumberCardController = (cardNumber) => {
    if (!cardNumber) {
        console.warn('no card number root element passed.');
        return undefined;
    }

    const countStart = Number(cardNumber.dataset.countStart);
    const countMillisec = Number(cardNumber.dataset.millisec);
    const countEnd = Number(cardNumber.dataset.countEnd)
        ? Number(cardNumber.dataset.countEnd)
        : 0;

    cardNumber.innerText = countStart;
    const amountToCount = countEnd - countStart;

    const isCardNumberInViewport = () => {
        const cardNumberRect = cardNumber.getBoundingClientRect();
        return cardNumberRect.top <= window.innerHeight && cardNumberRect.top >= (-1 * cardNumberRect.height);
    };

    const countUp = (fraction) => {
        cardNumber.innerText = Math.floor((amountToCount * fraction) + countStart);
    };

    let countingStarted = false;
    window.addEventListener('scroll', function () {
        if (isCardNumberInViewport() && !countingStarted) {
            animate(tween.easeOut, countMillisec, countUp, () => {
                cardNumber.innerText = countEnd;
            });
            countingStarted = true;
        }
    }, false);
};
