// always import all images
function importAll(r) {
    return r.keys().map(r);
}

const importedImages = importAll(require.context('../_assets/images', false, /\.(svg|png|jpe?g)$/));

// Lazysizes
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';

// Bootstrap
import 'bootstrap/js/dist/collapse'

// Smooth Scrolling Polifill
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// Global
import '../_assets/scripts/mobile-viewport-fix';
import '../_assets/scripts/anchors';
import '../_assets/scripts/scroll-handler';

// Partials
import './partials/navigation';

// Shared
import '../_shared/cookie-consent';
