import './gallery';
import './timeline';
import {precisionNetFormController} from './precision-net-form-controller';
import {usedVehiclesController} from './used-vehicles';
import {titleNumberCardController} from './number-text-card';
import {sliderController} from '../_layout/partials/slider';

const initBlocks = (isDevMode) => {
    const usedVehiclesBlocks = document.querySelectorAll('.used-vehicles');
    usedVehiclesBlocks.forEach((usedVehicleBlock) => {
        usedVehiclesController(usedVehicleBlock);
    });
    if (isDevMode) {
        console.info('used vehicles blocks loaded');
    }

    const precisionNetForms = document.querySelectorAll('.block-precision-net-form');
    precisionNetForms.forEach((precisionNetForm) => {
        precisionNetFormController(precisionNetForm);
    });
    if (isDevMode) {
        console.info('precision net forms loaded');
    }

    const titleNumberCardNumbers = document.querySelectorAll('.card-number');
    titleNumberCardNumbers.forEach((cardNumber, idx) => {
        titleNumberCardController(cardNumber);
    });
    if (isDevMode) {
        console.info('loaded title card numbers');
    }

    const sliders = document.querySelectorAll('.slider');
    Array.from(sliders).forEach(slider => {
        const customOptions = {};
        const autoplayDelay = slider.dataset.autoplayDelay;
        if (autoplayDelay) {
            customOptions.autoplay = {
                delay: autoplayDelay
            };
        }
        sliderController(slider, customOptions);
    });
    if (isDevMode) {
        console.info('sliderController loaded');
    }
};

window.initBlocks = initBlocks;
