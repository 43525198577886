import Swiper, {Navigation, Pagination, Autoplay} from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination, Autoplay]);

const options = {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    slidesPerView: 1,
    centeredSlides: true,

    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
};

export const sliderController = (slider, customOptions = {}) => {
    let consolidatedOptions = {...options};

    Object.entries(customOptions).forEach((option) => {
        const [key, value] = option;
        consolidatedOptions = {...consolidatedOptions, [key]: value};
    });

    if (slider) {
        const slides = slider.querySelectorAll('.swiper-slide');
        if (!slides) {
            return;
        }

        if (slides.length > 1) {
            consolidatedOptions.loop = true;
        }

        const sliderContainer = slider.querySelector('.swiper-container');
        const id = sliderContainer.id;

        consolidatedOptions = {
            ...consolidatedOptions,
            pagination: {
                el: `.swiper-pagination-${id}`,
                clickable: true,
            },
            navigation: {
                nextEl: `.swiper-button-next-${id}`,
                prevEl: `.swiper-button-prev-${id}`,
            }
        };

        new Swiper(`#${id}`, consolidatedOptions);
    }
};

window.sliderController = sliderController;
