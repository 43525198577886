// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
import {debounce} from "./utils";

const setVhVariable = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
setVhVariable();

window.addEventListener('resize', () => {
    debounce(setVhVariable);
});
