/**
 * @param {NodeList} links
 */
import {getElementCoords} from './utils';

let anchorsController = (links) => {
    const goToAnchor = (anchorName) => {
        const anchor = document.querySelector(`a[name=${anchorName}]`);

        if (anchor) {
            scroll({
                top: getElementCoords(anchor).top,
                behavior: 'smooth'
            });
        }
    };

    const clickHandler = (link) => (e) => {
        e.preventDefault();
        const anchorName = link.getAttribute('href').replace('#', '');
        goToAnchor(anchorName);
    };

    if (links) {
        Array.from(links).forEach(link => {
            link.addEventListener('click', clickHandler(link));
        });
    }

    setTimeout(() => {
        if (window.location.hash) {
            const anchorName = window.location.hash.replace('#', '');
            goToAnchor(anchorName);
        }
    }, 500);
};

window.anchorsController = anchorsController;
