import {getCookie, Observable, setCookie} from '../_assets/scripts/utils';

const transitionDuration = 250;

const cookieConsentController = (overlay, settings = {}) => {
    if (
        window.location.pathname.match(/^\/datenschutz/) ||
        window.location.pathname.match(/^\/fr\/politique-confidentialite/)
    ) {
        return undefined;
    }

    const cookieConsentAccepted = Observable(0);

    const predefinedSettings = {
        cookieBannerId: 'cookie-consent',
        cname: 'cookie-consent',
        cvalue: 0,
        exdays: 356
    };

    const mergedSettings = {
        ...predefinedSettings,
        ...settings
    };

    const cookieBanner = document.getElementById(mergedSettings.cookieBannerId);
    if (!cookieBanner) {
        console.warn('cookieConsentController: no cookie banner found');
        return undefined;
    }

    if (!overlay) {
        console.warn('cookieConsentController: no overlay found');
        return undefined;
    }

    const cookie = getCookie(mergedSettings.cookieBannerId);

    const showCookieBanner = () => {
        cookieBanner.style.display = 'block';
        cookieBanner.style.pointerEvents = 'all';
        overlay.style.display = 'block';
        $(overlay).animate({
            opacity: 1,
        });
        $(cookieBanner).animate({
            opacity: 1,
        });
    };

    const hideCookieBanner = () => {
        $(overlay).animate({
            opacity: 0,
        }, transitionDuration, function () {
            overlay.style.display = 'none';
        });
        $(cookieBanner).animate({
            opacity: 0,
        }, transitionDuration, function () {
            cookieBanner.style.display = 'none';
            cookieBanner.style.pointerEvents = 'none';
        });
    };

    if (cookie && Number(cookie)) {
        return undefined;
    } else {
        showCookieBanner();
    }

    cookieConsentAccepted.onChange((value) => {
        if (value) {
            setCookie(mergedSettings.cname, value, mergedSettings.exdays);
        }
    });

    return {
        cookieConsentAccepted,
        showCookieBanner,
        hideCookieBanner
    };
};

const cookieConsentView = (cookieConsentCtrl, settings = {}) => {
    const predefinedSettings = {
        acceptButtonId: 'cookie-consent-accept',
    };

    const mergedSettings = {
        ...predefinedSettings,
        ...settings
    };

    const acceptButton = document.getElementById(mergedSettings.acceptButtonId);
    if (!acceptButton) {
        console.warn('cookieConsentView: no cookie consent accept button found');
        return undefined;
    }

    acceptButton.onclick = () => {
        cookieConsentCtrl.cookieConsentAccepted.setValue(1);
        cookieConsentCtrl.hideCookieBanner();
    };
};

window.cookieConstentController = cookieConsentController;
window.cookieConsentView = cookieConsentView;
