export const debounce = (func, t) => {
    const time = t || 100;
    let timer;

    if (timer) {
        clearTimeout(timer);
    }

    timer = setTimeout(func, time);
};

/**
 * @typedef {function(*)} Observable
 * @param value
 * @returns Observable
 */
export const Observable = value => {
    const listeners = [];

    /**
     * @typedef {{
     *   getValue: (function(): *),
     *   onChange: (function(function(*,*))),
     *   setValue: (function(*): (undefined))
     * }} Observable
     */
    return {
        onChange: callback => {
            listeners.push(callback);
            callback(value, value);
        },
        getValue: () => value,
        setValue: newValue => {
            const oldValue = value;
            value = newValue;
            listeners.forEach(callback => callback(value, oldValue));
        }
    };
};

/**
 * @typedef {function([])} ObservableList
 * @param list
 * @returns
 * @constructor
 */
export const ObservableList = list => {
    const addListeners = [];
    const delListeners = [];
    const add = item => {
        list.push(item);
        addListeners.forEach(listener => listener(item));
        return item;
    };

    const removeAt = array => index => array.splice(index, 1);
    const removeItem = array => (item, findIdxFn) => {
        const i = findIdxFn ? array.findIndex(findIdxFn) : array.indexOf(item);
        if (i >= 0) removeAt(array)(i);
    };
    const listRemoveItem = removeItem(list);
    const delListenersRemove = removeAt(delListeners);
    const del = (item, findIdxFn) => {
        listRemoveItem(item, findIdxFn);
        const safeIterate = [...delListeners]; // shallow copy as we might change listeners array while iterating
        safeIterate.forEach((listener, index) => listener(item, () => delListenersRemove(index)));
    };

    const contains = item => list.find(observable => observable.getValue().id === item.getValue().id);

    /**
     * @typedef {{
     *   add: (function(*=): *),
     *   addIfNotExists: (function(*=): *),
     *   removeDeleteListener: (function(*=, *=): void),
     *   onDel: (function(*=): number),
     *   count: (function(): *),
     *   clear: (function(): *),
     *   del: del,
     *   countIf: (function(*): *),
     *   list: *,
     *   onAdd: (function(*=): number)
     * }} observableList
     */
    return {
        onAdd: listener => addListeners.push(listener),
        onDel: listener => delListeners.push(listener),
        add: add,
        addIfNotExists: item => {
            if (!contains(item)) {
                add(item);
            }
            return item;
        },
        del: del,
        removeDeleteListener: removeItem(delListeners),
        count: () => list.length,
        countIf: pred => list.reduce((sum, item) => pred(item) ? sum + 1 : sum, 0),
        clear: () => list.forEach(item => del(item)),
        list
    };
};

export const viewportSize = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
};

// if breakpoints are not set in the :root style, then the functions fall back to predefined values
export const rootBreakpoints = {
    xs: Number(getComputedStyle(document.documentElement).getPropertyValue('--xs-breakpoint').replace('px', '')),
    sm: Number(getComputedStyle(document.documentElement).getPropertyValue('--sm-breakpoint').replace('px', '')),
    md: Number(getComputedStyle(document.documentElement).getPropertyValue('--md-breakpoint').replace('px', '')),
    lg: Number(getComputedStyle(document.documentElement).getPropertyValue('--lg-breakpoint').replace('px', '')),
    xl: Number(getComputedStyle(document.documentElement).getPropertyValue('--xl-breakpoint').replace('px', '')),
};

/**
 * @param {string} size
 * @returns {boolean}
 */
export const isViewPortMinWidth = (size) => {
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    switch (size.toLowerCase()) {
        case viewportSize.xs:
            return vw >= (rootBreakpoints.xs ? rootBreakpoints.xs : 0);
        case viewportSize.sm:
            return vw >= (rootBreakpoints.sm ? rootBreakpoints.sm : 576);
        case viewportSize.md:
            return vw >= (rootBreakpoints.md ? rootBreakpoints.md : 768);
        case viewportSize.lg:
            return vw >= (rootBreakpoints.lg ? rootBreakpoints.lg : 992);
        case viewportSize.xl:
            return vw >= (rootBreakpoints.xs ? rootBreakpoints.xs : 1200);
    }
};

/**
 * @param {string} size
 * @returns {boolean}
 */
export const isViewPortMaxWidth = (size) => {
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    switch (size.toLowerCase()) {
        case viewportSize.xs:
            return vw < (rootBreakpoints.xs ? rootBreakpoints.xs : 0);
        case viewportSize.sm:
            return vw < (rootBreakpoints.sm ? rootBreakpoints.sm : 576);
        case viewportSize.md:
            return vw < (rootBreakpoints.md ? rootBreakpoints.md : 768);
        case viewportSize.lg:
            return vw < (rootBreakpoints.lg ? rootBreakpoints.lg : 992);
        case viewportSize.xl:
            return vw < (rootBreakpoints.xs ? rootBreakpoints.xs : 1200);
    }
};

/**
 * @param {Element} elem
 * @returns {{top: number, left: number}}
 */
export const getElementCoords = (elem) => {
    let box = elem.getBoundingClientRect();

    return {
        top: box.top + pageYOffset,
        left: box.left + pageXOffset
    };
};

/**
 * @param {Node | Element} element
 * @param {string} type
 * @return {{parentNode}|*|undefined}
 */
export const findParentElementWithType = (element, type) => {
    if (!element.parentNode) {
        return undefined;
    }

    if (element.tagName.toLowerCase() === type) {
        return element;
    }

    return findParentElementWithType(element.parentNode, type);
};

/**
 * @link https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 *
 * @param str
 * @param seed
 * @return {number}
 *
 * @description 53-bit hash similar to well-known MurmurHash/xxHash algorithms. keep in mind this is not a secure algorithm,
 * if privacy/security is a concern, this is not for you.
 */
export const cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;

    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export const STORED_SCROLL_POS = 'scroll_pos';
export const WAS_LINK_CLICK = 'was_link_click';

export const storedScrollPos = {
    setScrollPos: (pos) => sessionStorage.setItem(`${STORED_SCROLL_POS}_${cyrb53(window.location.href)}`, String(pos)),
    getScrollPos: () => sessionStorage.getItem(`${STORED_SCROLL_POS}_${cyrb53(window.location.href)}`),
    clearScrollPos: () => sessionStorage.removeItem(`${STORED_SCROLL_POS}_${cyrb53(window.location.href)}`),
    /**
     * @param {Number} value
     */
    setWasLinkClick: (value) => sessionStorage.setItem(WAS_LINK_CLICK, String(value)),
    getWasLinkClick: () => Number(sessionStorage.getItem(WAS_LINK_CLICK)),
    clearWasLinkClick: () => sessionStorage.removeItem(WAS_LINK_CLICK),
};

export const tween = (() => {
    const linear = x => x;

    const slope = alpha => x => {
        if (x < 0) return 0;
        if (x > 1) return 1;
        const nom = Math.pow(x, alpha);
        return nom / (nom + Math.pow(1 - x, alpha));
    };

    const easeBoth = slope(1.3);

    const easeIn = fraction =>
        fraction < 0.5
            ? easeBoth(fraction)
            : linear(fraction);

    const easeOut = fraction =>
        fraction > 0.5
            ? easeBoth(fraction)
            : linear(fraction);

    return {
        linear,
        slope,
        easeBoth,
        easeIn,
        easeOut
    };
})();

/**
 * Simple animation function
 *
 * @param tweening
 * @param millisecs
 * @param callback
 * @param onDone
 */
export const animate = (tweening, millisecs, callback, onDone) => {
    let start = null;

    function paint(timestamp) {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        const fraction = progress / millisecs;
        const value = tweening(fraction);
        callback(value);
        if (progress < millisecs) {
            window.requestAnimationFrame(paint);
        } else {
            callback(1);
            if (onDone) onDone();
        }
    }

    callback(0);
    window.requestAnimationFrame(paint);
};

export const setCookie = (cname, cvalue, exdays) => {
    const date = new Date();
    date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + date.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};
