let GalleryController = (gallery) => {
    if (!gallery) {
        console.warn('no gallery block passed to timelineController');
        return undefined;
    }

    const galleryImages = Array.from(gallery.querySelectorAll('.hero-image'));
    const thumbnailWrappers = Array.from(gallery.querySelectorAll('.hero-gallery-image-wrapper'));

    const getGalleryImageById = (id) => {
        return galleryImages.find(galleryImage => galleryImage.id === id);
    };

    const deselectAllThumbnails = () => {
        thumbnailWrappers.forEach(thumbnail => {
            thumbnail.classList.remove('selected');
        })
    }

    const hideAllGalleryImages = () => {
        galleryImages.forEach(galleryImage => {
            galleryImage.setAttribute('hidden', true);
        })
    }

    const showGalleryImage = (id) => {
        const galleryImage = getGalleryImageById(id);
        galleryImage.removeAttribute('hidden');
    }

    const selectThumbnail = (thumbnail) => {
        thumbnail.classList.add('selected');
    }

    /**
     * @typedef {{
     *      galleryImages: [Element],
     *      thumbnailWrappers: [Element],
     *      getGalleryImageById: (function(string): Element),
     *      deselectAllThumbnails: (function(): undefined),
     *      selectThumbnail: (function(Element): undefined),
     *      hideAllGalleryImages: (function(): undefined),
     *      showGalleryImage: (function(number): undefined)
     * }} GalleryController
     */
    return {
        galleryImages,
        thumbnailWrappers,
        getGalleryImageById,
        deselectAllThumbnails,
        selectThumbnail,
        hideAllGalleryImages,
        showGalleryImage
    };
};

/**
 *
 * @param {GalleryController} controller
 */
const registerClickHandler = (controller) => {
    controller.thumbnailWrappers.forEach(thumbnail => {
        thumbnail.onclick = () => {
            controller.deselectAllThumbnails();
            controller.selectThumbnail(thumbnail);
            controller.hideAllGalleryImages();
            controller.showGalleryImage(thumbnail.dataset.target)
        }
    })
};

/**
 *
 * @param {GalleryController} controller
 */
let startGallery = (controller) => {
    registerClickHandler(controller);
};

window.GalleryController = GalleryController;
window.startGallery = startGallery;
